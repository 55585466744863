.block {
  border-radius: var(--border-radius-small);
  height: 15px;
  color: black;
  display: block;
}


.pulsate {
  background: linear-gradient(-45deg, #dddddd, #a2aebe, #dddddd, #a2aebe);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}

.block-img {
  width: 50px !important;
  height: 50px !important;
}

.skeleton_max-width {
  width: 100% !important;
}

/* Table */
.skeleton-table__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-small);
}
.skeleton-table-item {
  grid-template-columns: auto 1fr;
  display: grid;
  align-items: center;
  gap: var(--gap-small);
}

.skeleton-table__description {
  display: flex;
  gap: var(--gap-small);
}

.skeleton-table__text {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.skeleton-table__description .block {
  width: 100px !important;
}


@media (max-width: 1150px) {
  .skeleton-table__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .skeleton-table__description {
    flex-wrap: wrap;
  }
}

@media (max-width: 900px) {
  .skeleton-table__list {
    grid-template-columns: repeat(1, 1fr);
  }
}


@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
