.alert {
  position: fixed;
  left: 50%;
  top: -250px;
  
  transform: translateX(-50%);
  
  width: 100%;
  max-width: max-content;

  border: var(--border-solid-accent);
  border-radius: var(--border-radius-small);

  padding: var(--padding-big);
  background-color: white;
  
  transition: .4s ease-in-out top;
  z-index: 9;
}

.alert_active {
  top: 20px;
}

.alert-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-small);
}

.alert-content h2 {
  color: black;
}

.alert-buttons {
  width: 100%;
  display: flex;
  gap: var(--gap-small);
}

.alert-buttons button {
  width: 100%;
}