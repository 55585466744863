.clicker-statistic {
  display: grid;
  gap: var(--gap-small);
}

.clicker-statistic .clicker-items p {
  font-size: 28px;
}

.clicker-statistic .clicker-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-small);
}
.clicker-statistic .clicker-items .clicker-component {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: var(--gap-small);
}

.clicker-tasks {
  display: grid;
  gap: var(--gap-small);
}

.clicker-tasks__header {
  display: flex;
  gap: var(--gap-small);
  justify-content: space-between;
  align-items: center;
}

.clicker-tasks .clicker-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-small);
}

.clicker-tasks__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap-small);
  cursor: pointer;
  animation: appearSecond 0.5s forwards; /* Запуск анимации */
}

.clicker-tasks__item_inactive {
  background-color: #658cb5;
  color: white;
}

.clicker-tasks__item svg,
.clicker-tasks__item-icon img {
  width: 50px;
  height: auto;
  /* aspect-ratio: 1 / 1;
  object-fit: cover; */
}

.clicker-tasks__item-icon {
  width: 50px;
  height: 50px;
  /* background-size: cover; */
  display: flex;
  align-items: center;
}

.clicker-tasks__left {
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.clicker-tasks__item-buttons {
  display: flex;
  gap: var(--gap-small);
}

@media (max-width: 870px) {
  .clicker-tasks .clicker-items {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 570px) {
  .clicker-statistic .clicker-items {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 430px) {
  .clicker-tasks__item {
    flex-direction: column;
    align-items: normal;
  }

  .clicker-tasks__item-buttons button {
    width: 100%;
  }

  .clicker-tasks__header {
    flex-direction: column;
    align-items: normal;
  }

  .clicker-tasks__header button {
    max-width: 100%;
  }
}

.clicker-users {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.clicker-users__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-small);
}

.clicker-users__item {
  grid-template-columns: auto 1fr;
  display: grid;
  align-items: center;
  gap: var(--gap-small);
}

.clicker-users__item svg,
.clicker-users__item img {
  width: 70px;
  height: 70px;
  aspect-ratio: 1 / 1;
  padding: 15px;
  border-radius: 1000px;
  background-color: #658cb5;
}

.clicker-users__item svg path {
  fill: white;
}

.users-item__description {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1150px) {
  .clicker-users__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 900px) {
  .clicker-users__list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.pagination {
  width: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: white;
  gap: var(--gap-small);
}

.clicker-utm {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.clicker-utm .clicker-items{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.clicker-utm .clicker-items .clicker-component{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: var(--gap-small);
}

.clicker-utm .clicker-items p {
  font-size: 28px;
}


@media (max-width: 570px) {
  .clicker-utm .clicker-items {
    grid-template-columns: 1fr;
  }
}