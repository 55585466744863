* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Raleway;
  font-size: 16px;
  font-variant-numeric: lining-nums;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background: rgb(29, 155, 215);
  background: radial-gradient(
    circle,
    rgba(29, 155, 215, 1) 0%,
    rgba(41, 47, 105, 1) 88%
  );
  padding: var(--padding-big);
}

a {
  text-decoration: none;
}

label {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

label input {
  width: 100%;
}

input {
  padding: var(--padding-small);
  border: var(--border-solid-accent);
  border-radius: var(--border-radius-small);
  outline: none;
}

select {
  padding: var(--padding-small);
  border: var(--border-solid-accent);
  border-radius: var(--border-radius-small);
  outline: none;
  width: 100%;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 40px;
  border-radius: var(--border-radius-small);
  background-color: var(--green-color);
  color: white;
  padding: 0 var(--padding-big);
  cursor: pointer;
}

button svg {
  height: 20px !important;
  aspect-ratio: 1 / 1;
}

.button-max-content {
  max-width: max-content;
}

.button-square {
  padding: 5px !important;
  width: auto;
  aspect-ratio: 1 / 1;
}

.button-red {
  background-color: var(--red-color);
}

.hidden {
  display: none !important;
}
h1 {
  color: white;
  text-align: center;
  font-size: 36px;
}

h2 {
  color: white;
  font-size: 24px;
  font-weight: 500;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 570px) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 20px;
  }
  h3{
    font-size: 18px;
  }
  button {
    height: 35px;
  }
}


.clicker {
  display: flex;
  flex-direction: column;
  gap: var(--gap-big);
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}

.clicker-component {
  background-color: var(--component-color);
  padding: var(--padding-small);
  border-radius: var(--border-radius-small);
  border: var(--border-solid-main);
  width: 100%;
}

.form-title {
  font-size: 28px;
  color: black;
  text-align: center;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}